<template>
  <section>
    <template v-if="authRequiredForOrderType">
      <p>{{ $t("authIsRequiredForOrderType") }}</p>
      <LoginBtn />
    </template>
    <template v-else-if="!userPhoneConfigured && orderTypeIsToHomeOrToPickUp">
      <p>{{ $t("phoneIsRequiredForOrderType") }}</p>
      <ConfigPhoneBtn />
    </template>
    <SelectAddressBtn v-else-if="orderTypeIsToHome" />
  </section>
</template>
<script>
import {
  orderTypeStoreGetters,
  orderTypeName,
} from "@/store/modules/orderType/constNames";
import { userInfoStoreGetters } from "@/store/modules/userInfo/constNames";
import { isAuthenticated } from "@/helpers";

export default {
  name: "RequirementsOrder",
  props: {
    settingOrder: { type: Boolean, default: false },
  },
  components: {
    ConfigPhoneBtn: () => import("./buttons/ConfigPhoneBtn"),
    LoginBtn: () => import("./buttons/LoginBtn"),
    SelectAddressBtn: () => import("./buttons/SelectAddressBtn"),
  },
  computed: {
    IHaveSelectedAnOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    authRequiredForOrderType() {
      return !isAuthenticated() && this.orderTypeIsToHomeOrToPickUp;
    },
    orderTypeIsToHomeOrToPickUp() {
      return this.orderTypeIsToHome || this.orderTypeIsToPickUp;
    },
    orderTypeIsToHome() {
      return this.IHaveSelectedAnOrderType === orderTypeName.AT_HOME;
    },
    orderTypeIsToPickUp() {
      return this.IHaveSelectedAnOrderType === orderTypeName.TO_PICK_UP;
    },
    userPhoneConfigured() {
      return this.$store.getters[userInfoStoreGetters.GET_USER_PHONE];
    },
  },
};
</script>
